import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  BodyOutputType,
  ToasterConfig,
  ToasterService,
} from "angular2-toaster";
import { combineLatest, Subscription, from } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { AuthService } from "./core/auth/auth.service";
import { Mandator } from "./core/model/mandator";
import { Operations } from "./core/odata/odata.coreapi";
import { ConfirmationModal } from "./core/popup/confirmation.modal";
import { GlobalService } from "./core/shared/global.service";
import { MandatorWizardModel } from "./global/mandator/wizard/mandator-wizard-model";
import { MandatorWizardService } from "./global/mandator/wizard/mandator-wizard.service";
import { NewsService } from "./news/news.service";
import { Utils } from "./core/tools/utils";
import { ODataCoreService } from "./core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { LoginService } from "./core/shared/login.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  isCollapsed: boolean = true;

  config: ToasterConfig = new ToasterConfig({
    animation: "fade",
    timeout: 5000,
    mouseoverTimerStop: false,
    bodyOutputType: BodyOutputType.TrustedHtml,
  });

  mandators: Mandator[];
  _mandator: Mandator;

  isLoggedIn: boolean;
  newsCount: number = 0;

  @ViewChild("advPopupContent")
  advPopupContent: any;
  mandatorWizardModel: MandatorWizardModel;

  @ViewChild("contactPopupContent")
  contactPopupContent: any;
  message: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    public authService: AuthService,
    private router: Router,
    private loginService: LoginService,
    private globalService: GlobalService,
    private newsService: NewsService,
    private modalService: NgbModal,
    private mandatorWizardService: MandatorWizardService,
    private toasterService: ToasterService,
    private odataCoreService: ODataCoreService,
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang("de");
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use("de");
  }

  ngOnInit() {
    // collapse menu on navigation
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.isCollapsed = true;
        }
      })
    );

    this.subscriptions.add(
      combineLatest(
        this.authService.isLoggedIn(),
        this.authService.mandatorChanged()
      ).subscribe(([isLoggedIn]) => {
        this.isLoggedIn = isLoggedIn;
        if (isLoggedIn) {
          this.newsCount = 0;
          this.subscriptions.add(
            from(
              this.odataCoreService.Account.Query()
                .OrderBy("Name")
                .Expand((x) => {
                  x.Expand("Rights", (y) => {
                    y.Filter((z) =>
                      z.EqualsField(
                        "Identity",
                        Guid.parse(localStorage.getItem("infoUserIdentity"))
                      )
                    );
                  });
                  x.Expand("Statistics"),
                    x.Expand("User"),
                    x.Expand("AccountDataProcessingAgreement", (y) => {
                      y.Expand("Country");
                    }),
                    x.Expand("Profile");
                })
                .Exec()
                .then((x) => x.value)
            )
              .pipe(
                map((res) =>
                  res.map((i) => {
                    Utils.mapAllJSONDatesToDates(i);
                    return Object.assign(new Mandator(), i);
                  })
                )
              )
              .subscribe((mandators) => {
                this.mandators = mandators;

                if (this.mandators && this.mandators.length) {
                  // read mandator from local storage if available
                  const mandatorId = this.authService.getMandator();
                  if (mandatorId) {
                    const findMandator = this.mandators.filter(
                      (m) => m.Identity === mandatorId
                    );
                    if (findMandator && findMandator.length > 0) {
                      this.mandator = findMandator[0];
                    }
                  }

                  // preselect mandator from user config
                  if (!this.mandator) {
                    this.subscriptions.add(
                      this.loginService
                        .readSettings(AuthService.USER_MANDATOR_CONFIG)
                        .subscribe((res) => {
                          if (res) {
                            const findMandator = this.mandators.filter(
                              (m) => m.Identity === res["value"]
                            );
                            if (findMandator && findMandator.length > 0) {
                              this.mandator = findMandator[0];
                              this.authService.setMandator(res["value"]);
                            }
                          }

                          // preselect first mandator
                          if (!this.mandator) {
                            this.mandator = this.mandators[0];
                            this.chooseMandator(this.mandator);
                          }

                          // get news count for mandator
                          this.subscriptions.add(
                            this.loginService
                              .readSettings(
                                "Ob2Webclient_LastNotificationDate_" +
                                localStorage.getItem("mandator")
                              )
                              .pipe(
                                switchMap((date) => {
                                  let cutoff = new Date();
                                  cutoff.setDate(cutoff.getDate() - 30);
                                  if (date) {
                                    const cutoff2 = new Date(date);
                                    if (cutoff.getTime() < cutoff2.getTime()) {
                                      cutoff = cutoff2;
                                    }
                                  }

                                  return this.odataCoreService.ActionLog.Query()
                                    .Filter((x) =>
                                      x.GreaterThanOrEquals("Created", cutoff)
                                    )
                                    .Count()
                                    .then((x) => x);
                                })
                              )
                              .subscribe((res) => {
                                this.newsCount = +res;
                              })
                          );
                        })
                    );
                  } else {
                    // get news count for mandator
                    this.subscriptions.add(
                      this.loginService
                        .readSettings(
                          "Ob2Webclient_LastNotificationDate_" +
                          localStorage.getItem("mandator")
                        )
                        .pipe(
                          switchMap((date) => {
                            let cutoff = new Date();
                            cutoff.setDate(cutoff.getDate() - 30);
                            if (date) {
                              const cutoff2 = new Date(date);
                              if (cutoff.getTime() < cutoff2.getTime()) {
                                cutoff = cutoff2;
                              }
                            }
                            return this.odataCoreService.ActionLog.Query()
                              .Filter((x) =>
                                x.GreaterThanOrEquals("Created", cutoff)
                              )
                              .Count()
                              .then((x) => x);
                          })
                        )
                        .subscribe((res) => {
                          this.newsCount = +res;
                        })
                    );
                  }
                }
              })
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  set mandator(mandator: Mandator) {
    this.authService.serviceContactMandator =
      mandator.AccountType === Operations.AccountTypes.ServiceProvider;
    this.authService.userReadOnly = mandator.Rights[0].CanEdit === false;

    mandator.isOwnerOwner =
      mandator.User.Email === this.authService.getUserEmail();
    if (mandator.isOwnerOwner) {
      if (
        !mandator?.AccountDataProcessingAgreement &&
        (!localStorage.getItem("data_processing_agreement") ||
          localStorage.getItem("data_processing_agreement") === "false")
      ) {
        localStorage.setItem("data_processing_agreement", "true");

        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = this.translateService.instant(
          "AppComponent._modal_title"
        );
        modalRef.componentInstance.message = this.translateService.instant(
          "AppComponent._modal_message",
          { mandatorId: mandator.Identity }
        );
        modalRef.componentInstance.yesButton = this.translateService.instant(
          "AppComponent._modal_yes"
        );
        modalRef.componentInstance.cancelButton = this.translateService.instant(
          "AppComponent._modal_cancel"
        );

        modalRef.result
          .then((val) => {
            if (val === ConfirmationModal.YES_VALUE) {
              // mandator data for popup
              this.mandatorWizardService.startWizard();
              this.mandatorWizardModel = this.mandatorWizardService.getCurrentMandator();
              // we need to load countries for country data
              if (mandator.Profile != null) {
                this.subscriptions.add(
                  from(
                    this.odataCoreService.Country.Query()
                      .OrderBy("Id")
                      .Exec()
                      .then((x) => x.value)
                  )
                    .pipe(
                      map((res) =>
                        res.map((i) => Utils.mapAllJSONDatesToDates(i))
                      )
                    )
                    .subscribe((countries) => {
                      this.mandatorWizardService.editWizard(
                        mandator,
                        countries.find(
                          (c) =>
                            c.Id === mandator.Profile.Address.CountryInfo.Id
                        )
                      );
                    })
                );
              } else {
                this.subscriptions.add(
                  from(
                    this.odataCoreService.Country.Query()
                      .OrderBy("Id")
                      .Exec()
                      .then((x) => x.value)
                  )
                    .pipe(
                      map((res) =>
                        res.map((i) => Utils.mapAllJSONDatesToDates(i))
                      )
                    )
                    .subscribe((countries) => {
                      this.mandatorWizardService.editWizard(
                        mandator,
                        countries.find((c) => c.Id === 0)
                      );
                    })
                );
              }
              // open adv popup with mandator data
              this.modalService
                .open(this.advPopupContent, { windowClass: "adv-modal" })
                .result.then((result) => {
                  this.odataCoreService.Account.Get()
                    .Key(Guid.parse(mandator.Identity))
                    .Actions()
                    .SignDataProcessingAgreementInOperationsOnAccountInOperations()
                    .Execute()
                    .then((res) => {
                      mandator.AccountDataProcessingAgreement = <
                        Operations.AccountDataProcessingAgreement
                        >{}; // create at least empty agreement so that we know it is agreed
                    });
                })
                .catch(() => {
                  // do nothing, just stay on page
                });
            }
          })
          .catch(() => {
            // do nothing, just stay on page
          });
      }
    }
    this._mandator = mandator;
  }

  get mandator() {
    return this._mandator;
  }

  get userEmail() {
    const userEmail = this.authService.getUserEmail();
    if (userEmail && userEmail.length > 23) {
      return userEmail.substring(0, 18).concat(" ...");
    } else {
      return userEmail;
    }
  }

  logout() {
    this.authService.logout();
  }

  profile() {
    this.router.navigate(["/global/profile"]);
  }

  changePassword() {
    this.router.navigate(["/global/change-password"]);
  }

  manageMandators() {
    this.router.navigate(["/global/mandator"]);
  }

  manageUsers() {
    this.router.navigate(["/global/manage-users"]);
  }

  export() {
    this.router.navigate(["/global/export"]);
  }

  termsOfUse() {
    this.router.navigate(["/global/terms-of-use"]);
  }

  dataProtection() {
    this.router.navigate(["/global/data-protection"]);
  }

  chooseMandator(mandator: Mandator): void {
    localStorage.setItem("data_processing_agreement", "false");

    this.mandator = mandator;
    this.authService.setMandator(mandator.Identity);
    this.subscriptions.add(
      this.loginService
        .saveSettings(AuthService.USER_MANDATOR_CONFIG, mandator.Identity)
        .subscribe((res) => {
          if (mandator.isOwner && !mandator.Statistics.BuildingCount) {
            // do full page reload to building wizard
            const url = this.router
              .createUrlTree(["/building", "wizard"])
              .toString();
            this.router
              .navigateByUrl("/skipThisPage", { skipLocationChange: true })
              .then(() => this.router.navigateByUrl(url));
          } else {
            // navigate to dashboard with full reload
            const url = this.router.createUrlTree(["/dashboard"]).toString();
            this.router
              .navigateByUrl("/skipThisPage", { skipLocationChange: true })
              .then(() => this.router.navigateByUrl(url));
          }
        })
    );
  }

  setAdvancedMode() {
    this.authService.advancedMode = !this.authService.advancedMode;
    // reload page
    const url = this.router.url;
    this.router
      .navigateByUrl("/skipThisPage", { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(url));
  }

  showContact() {
    this.modalService
      .open(this.contactPopupContent, { windowClass: "contact-modal" })
      .result.then((result) => {
        this.odataCoreService.Account.Get()
          .Key(Guid.parse(localStorage.getItem("mandator")))
          .Actions()
          .SendContactRequestInOperationsOnAccountInOperations()
          .Parameters(this.message)
          .Execute()
          .then((res) => {
            this.message = "";
            this.toasterService.pop(
              "info",
              "",
              this.translateService.instant("AppComponent._contact_success")
            );
          });
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }
}
