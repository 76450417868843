import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription, Observable, from } from "rxjs";
import { Common, Operations, PropertyCore, ObjektBrief } from "../../core/odata/odata.coreapi";
import { AuthService } from "./../../core/auth/auth.service";
import { ConfirmationModal } from "./../../core/popup/confirmation.modal";
import { Utils } from "./../../core/tools/utils";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { map } from "rxjs/operators";
import { Guid } from "guid-typescript";
import { Mandator } from "../../core/model/mandator";
import { LoginService } from "app/core/shared/login.service";
import { GlobalService } from "app/core/shared/global.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  isNewUser: boolean;
  accessCode: string;
  invitation: string;

  hasMandators: boolean;
  mandatorName: string;
  mandatorAddress: Common.Address = <Common.Address>{ CountryInfo: { Id: 1, Name: "Deutschland" } };

  model: Operations.User;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private toasterService: ToasterService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParamMap.subscribe((params) => {
        this.isNewUser = params.get("isNewUser") ? params.get("isNewUser") === "true" : false;
        this.accessCode = params.get("accessCode");
        this.invitation = params.get("invitation");

        this.subscriptions.add(
          from(
            this.odataCoreService.User.Query()
              .Filter((x) => x.EqualsField("Identity", Guid.parse(localStorage.getItem("infoUserIdentity"))))
              .Exec()
              .then((x) => x.value)
          )
            .pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
            .subscribe((profile) => {
              this.model = profile;
            })
        );

        if (this.isNewUser) {
          this.subscriptions.add(
            from(
              this.odataCoreService.Account.Query()
                .OrderBy("Name")
                .Exec()
                .then((x) => x.value)
            )
              .pipe(
                map((res) =>
                  res.map((i) => {
                    Utils.mapAllJSONDatesToDates(i);
                    return Object.assign(new Mandator(), i);
                  })
                )
              )
              .subscribe((mandators) => {
                if (
                  mandators &&
                  mandators.length &&
                  ((!this.accessCode && !this.invitation) ||
                    (this.accessCode &&
                      this.invitation &&
                      mandators.filter((m) => m.AccountType === Operations.AccountTypes.ServiceProvider).length))
                ) {
                  this.hasMandators = true;
                }
              })
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    this.odataCoreService.User.Patch()
      .Key(Guid.parse(this.model.Identity))
      .ValueType(this.odataCoreService.ODataTypes().User())
      .ValueProperty("Email", this.model.Email)
      .ValueProperty("FirstName", this.model.FirstName)
      .ValueProperty("LastName", this.model.LastName)
      .ValueProperty("IsMale", this.model.IsMale)
      .ValueProperty("IsReportSubscribed", this.model.IsReportSubscribed)
      .Exec()
      .then((x) => {
        this.toasterService.pop("success", "", this.translateService.instant("Profile.save_success"));
        localStorage.setItem("infoUserName", this.model.FirstName);
        localStorage.setItem("infoUserSurname", this.model.LastName);
        localStorage.setItem("infoUserEmail", this.model.Email);

        if (this.isNewUser) {
          if (!this.hasMandators) {
            // create mandator
            const guid = Utils.guid();

            this.odataCoreService.Account.Post()
              .ValueType(this.odataCoreService.ODataTypes().Account())
              .ValueProperty("Identity", guid)
              .ValueProperty("Name", this.mandatorName)
              .ValueProperty(
                "AccountType",
                this.accessCode && this.invitation
                  ? Operations.AccountTypes.ServiceProvider
                  : Operations.AccountTypes.FacilityManagement
              )
              .Exec()
              .then((res) => {
                this.odataCoreService.Account.Get()
                  .Key(Guid.parse(res.Identity))
                  .NavigateToArray<ObjektBrief.AccountProfile>("Profile")
                  .Patch()
                  .ValueType(this.odataCoreService.ODataTypes().AccountProfile())
                  .ValueProperty("Name", this.mandatorName)
                  .ValueProperty("Contact", this.model.FirstName + " " + this.model.LastName)
                  .ValueProperty("ContactMail", this.model.Email)
                  .ValueProperty("Address", this.mapAddress(this.mandatorAddress))
                  .Exec()
                  .then((x) => {
                    // change mandator to this new mandator
                    this.authService.setMandator(guid, true);
                    this.subscriptions.add(
                      this.loginService.saveSettings(AuthService.USER_MANDATOR_CONFIG, guid).subscribe((any) => {
                        // do not show adv until next login
                        localStorage.setItem("data_processing_agreement", "true");

                        // service provider mandator does not have building wizard, instead accept invitation
                        if (this.accessCode && this.invitation) {
                          this.subscriptions.add(
                            from(
                              this.odataCoreService.Account.Get()
                                .Key(Guid.parse(guid))
                                .Actions()
                                .AcceptInvitationInOperationsOnAccountInOperations()
                                .Parameters(this.invitation, this.accessCode)
                                .Execute()
                            ).subscribe(() => {
                              this.router.navigate(["/dashboard"]);
                            })
                          );
                        } else {
                          // user has mandator but no building
                          this.router.navigate(["/building", "wizard"]);
                        }
                      })
                    );
                  });
              });
          } else {
            // service provider mandator does not have building wizard, redirect to page where user can select service provider account
            if (this.accessCode && this.invitation) {
              this.router.navigate(["/global/service-provider"], {
                queryParams: {
                  accessCode: this.accessCode,
                  invitation: this.invitation,
                },
              });
            } else {
              // check building count
              this.subscriptions.add(
                from(
                  this.odataCoreService.Building.Query()
                    .Count()
                    .then((count) => {
                      if (count) {
                        this.router.navigate(["/dashboard"]);
                      } else {
                        // user has mandator but no building
                        this.router.navigate(["/building", "wizard"]);
                      }
                    })
                ).subscribe()
              );
            }
          }
        } else {
          this.router.navigate(["/dashboard"]);
        }
      });
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("Profile._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("Profile._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("Profile._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("Profile._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(["/dashboard"]);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
    if (address?.RegionInfo != null && address.RegionInfo.hasOwnProperty("Code")) {
      entity.Region = address.RegionInfo["Code"];
    }
    if (address?.CountryInfo != null && address.CountryInfo.hasOwnProperty("Code")) {
      entity.Country = address.CountryInfo["Code"];
    } else {
      entity.Country = "DE";
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}
