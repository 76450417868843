import { registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HttpClientJsonpModule,
} from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import {
  APP_INITIALIZER,
  LOCALE_ID,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment,
} from "@angular/router";
import { NgbCollapseModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToasterModule } from "angular2-toaster";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AppComponent } from "./app.component";
import { BuildingModule } from "./building/building.module";
import { ContractModule } from "./contract/contract.module";
import { AuthModule } from "./core/auth/auth.module";
import { AppConfig } from "./core/bootstrap/app.config";
import { CoreModule } from "./core/core.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { GlobalModule } from "./global/global.module";
import { LoginComponent } from "./login/login.component";
import { LoginLandingComponent } from "./login-landing/login-landing.component";
import { LogoutComponent } from "./logout/logout.component";
import { NewsModule } from "./news/news.module";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SearchModule } from "./search/search.module";
import { ServiceContactModule } from "./service-contact/service-contact.module";
import { ODataCoreService } from "../app/core/odata-services/odata.coreapi.service";
import { GoogleMapsConfig } from "./core/config/google-maps.config";
import { firstValueFrom } from "rxjs";

registerLocaleData(localeDe, "de");

export function loadGlobalModule() {
  return GlobalModule;
}
export function loadBuildingModule() {
  return BuildingModule;
}
export function loadContractModule() {
  return ContractModule;
}
export function loadServiceContactModule() {
  return ServiceContactModule;
}
export function loadDashboardModule() {
  return DashboardModule;
}
export function loadSearchModule() {
  return SearchModule;
}
export function loadNewsModule() {
  return NewsModule;
}

export function WStartMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length < 2) {
    return null;
  }

  const reg1 = /^w$/i;
  const reg2 = /^start$/i;
  const url1 = url[0].toString();
  const url2 = url[1].toString();

  if (url1.match(reg1) && url2.match(reg2)) {
    return { consumed: url };
  }

  return null;
}

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  { matcher: WStartMatcher, redirectTo: "/dashboard", pathMatch: "full" },
  { path: "global", loadChildren: loadGlobalModule },
  { path: "dashboard", loadChildren: loadDashboardModule },
  { path: "building", loadChildren: loadBuildingModule },
  { path: "contract", loadChildren: loadContractModule },
  { path: "service-contact", loadChildren: loadServiceContactModule },
  { path: "search", loadChildren: loadSearchModule },
  { path: "news", loadChildren: loadNewsModule },
  { path: "logout", component: LogoutComponent },
  { path: "login", component: LoginComponent },
  { path: "login-landing", component: LoginLandingComponent },
  { path: "**", component: PageNotFoundComponent },
];

export function loadAppConfig(gmConfig: GoogleMapsConfig, config: AppConfig) {
  return () => config.load().then(() => firstValueFrom(gmConfig.load()));
}
export function HttpLoaderFactory(http: HttpClient) {
  //add hash at the end of translation file so the browsers don't cache the file
  return new TranslateHttpLoader(
    http,
    "/assets/i18n/",
    ".json?cb=" + new Date().getTime()
  );
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoginComponent,
    LoginLandingComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GlobalModule,
    BuildingModule,
    ContractModule,
    ServiceContactModule,
    DashboardModule,
    SearchModule,
    NewsModule,
    AuthModule,
    CoreModule,
    NgbModule,
    RouterModule.forRoot(
      routes,
      //  { enableTracing: true } // <-- debugging purposes only)
      { relativeLinkResolution: "legacy" }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PdfViewerModule,
    ToasterModule.forRoot(),
    NgbCollapseModule,
  ],
  providers: [
    GoogleMapsConfig,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppConfig,
      deps: [GoogleMapsConfig, AppConfig, ODataCoreService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "de",
    },
    ODataCoreService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
